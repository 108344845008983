<template>
    <div style="width: 100%">
        <div class="row">
            <div class="owl-carousel owl-theme instruction-carousel">
                <div class="instruction-item">
                    <img class="ii-image" :src="asset('img/ins/step1.png')" alt=""/>
                    <p class="ii-title">The Best Just Listed/Sold System Ever!</p>
                    <p class="ii-subtitle">
                        Get your cards in the mail in less than 3 minutes.
                    </p>
                </div>
                <div class="instruction-item">
                    <img class="ii-image" :src="asset('img/ins/step2.png')" alt=""/>
                    <p class="ii-title">Smudge Proof Thick 6x9 Postcards Sent 1st Class</p>
                    <p class="ii-subtitle">
                        Cards are sent USPS 1st Class and printed within less than 24 hours.
                    </p>
                </div>
                <div class="instruction-item">
                    <img class="ii-image" :src="asset('img/ins/step3.png')" alt=""/>
                    <p class="ii-title">Track Your Responses With QR Codes</p>
                    <p class="ii-subtitle">
                        Get texts when anyone scans your QR code.
                    </p>
                </div>
                <div class="instruction-item">
                    <img class="ii-image" :src="asset('img/ins/step4.png')" alt=""/>
                    <p class="ii-title">Social Proof by Adding Reviews</p>
                    <p class="ii-subtitle">
                        Easily append your best review to gain public trust.
                    </p>
                </div>
                <div class="instruction-item">
                    <img class="ii-image" :src="asset('img/ins/step5.png')" alt=""/>
                    <p class="ii-title">Free Property List</p>
                    <p class="ii-subtitle">
                        Mailing list included with optional 'likely to sell'.
                    </p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "InstructionsCarousel",

    mounted() {
        this.initCarousel();
    },

    beforeUnmount() {
        this.destroyCarousel();
    },

    methods: {
        initCarousel() {
            let options = {
                items: 1,
                loop: true,
                margin: 10,
                autoplay: true,
                smartSpeed: 100,
                responsiveClass: false,
                autoWidth: false,
                dots: false,
                checkVisible: false
            };

            let owl = $('.instruction-carousel');
            owl.owlCarousel(options);
        },

        destroyCarousel() {
            let owl = $('.instruction-carousel');
            owl.trigger('destroy.owl.carousel');
        }
    }
}
</script>

<style scoped>

</style>
