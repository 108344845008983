<template>
    <h5> {{ title }} </h5>
    <div class="row">
        <div class="col-md-4 template" @click="$emit('thumbnailClicked', template_type, 1)" >
            <img
                class="img-fluid border rounded thumbnails"
                :src="template_image(1)"
            />
        </div>
        <div class="col-md-4 template" @click="$emit('thumbnailClicked', template_type, 2)" >
            <img
                class="img-fluid border rounded thumbnails"
                :src="template_image(2)"
            />
        </div>
        <div class="col-md-4 template" @click="$emit('thumbnailClicked', template_type, 3)" >
            <img
                class="img-fluid border rounded thumbnails"
                :src="template_image(3)"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: { template_type: String, title: String, whitelabel: String },
    data() {
        return {

        };
    },
    emits: ["thumbnailClicked"],
    methods: {
        template_image: function (image_id) {
            let root_folder = '';
            if(this.whitelabel) {
                root_folder = 'whitelabel/' + this.whitelabel + '/';
            }

            return this.asset(root_folder + 'img/examples/thumbnails/' + this.template_type + '-' + image_id + '.png');
        }
    }
};
</script>

<style>
.thumbnails {
    width: 90%
}
</style>
