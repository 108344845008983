<template>
    <div class="form-group">
        <label class="form-label" style="font-weight: bold"
            >To find your listings please enter your Zillow profile URL.</label
        >
        <div>
            <p>
                To find your profile please click
                <a href="https://www.zillow.com/agent-finder/" target="_blank"
                    >here</a
                >
                and search for your name. Once you find your profile copy the
                URL and paste it below.
            </p>
        </div>
        <div class="input-group">
            <input
                type="text"
                class="form-control"
                v-model="profile_url"
                placeholder="e.g. https://www.zillow.com/profile/franktommaso/"
            />
            <button
                class="btn btn-outline-primary"
                type="button"
                @click="$emit('formSubmitted', profile_url)"
            >
                Confirm!
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: { initial_profile_url: String },
    data() {
        return {
            profile_url: this.initial_profile_url
        };
    },
    emits: ["formSubmitted"]
};
</script>
