import './bootstrap';

import { createApp } from "vue";

import App from './App.vue';

import * as Sentry from "@sentry/vue";

import Vapor from 'laravel-vapor'
Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)
window.Vapor = Vapor

const app = createApp(App);

app.mixin({
    methods: {
        asset: window.Vapor.asset
    },
});

Sentry.init({
  app,
  dsn: "https://54a6c8920fe75ed25de5832d5b6df431@o92141.ingest.sentry.io/4505681959714816",
  integrations: [
    new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^\// ,/staging.justlistedjustsold.com/, /justlistedjustsold.com/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


app.mount('#app');
